const tabGallery = {
  init() {

    $('#tabGallery').lightGallery({
      selector: '.thumbnail'
    });

    // close init
  }
  // close const
};

export default tabGallery;
