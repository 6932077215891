const tabGalleryDesktop = {
  init() {

    $('#tabGalleryDesktop').lightGallery({
      selector: '.thumbnail_initial'
    });

    // close init
  }
  // close const
};

export default tabGalleryDesktop;
