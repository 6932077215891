const gallery = {
  init() {

    $('#lightgallery').lightGallery({
      selector: '.thumbnail'
    });

    // close init
  }
  // close const
};

export default gallery;
