// NPM dependencies
import $ from 'jquery';
import 'babel-polyfill';
import 'foundation-sites';
import objectFitImages from 'object-fit-images';
import 'slick-carousel';
import 'lightgallery';
import '@fortawesome/fontawesome-free';
// import 'jquery-ias';

// infiniteScroll doesn't seem to work with import, just require it
// To use $().infiniteScroll, we need to convert the infiniteScroll constructor into a JQuery plugin
var jQueryBridget = require('jquery-bridget');
var InfiniteScroll = require('infinite-scroll');
jQueryBridget('infiniteScroll', InfiniteScroll, $);

// Project Partials
// import Object from '../../src/components/03-ui/component-folder/component-file-name';
import slider from '../../src/components/02-objects/home-slider/home-slider';
import relatedSlider from '../../src/components/02-objects/related-slider/related-slider';
import gallery from '../../src/components/02-objects/gallery/gallery';
import relatedItems from '../../src/components/03-ui/relatedItems/relatedItems';
import tabGallery from '../../src/components/02-objects/directoryDetailsMobile/tabGallery';
import tabGalleryDesktop from '../../src/components/02-objects/directoryDetailsDesktop/tabGalleryDesktop';
import desktopNav from '../../src/components/02-objects/desktop-nav/desktop-nav';
// import JsIas from '../../src/components/02-objects/ias/ias';

// Import modules
import EmagineInfiniteScroll from './modules/infinite-scroll';

// Note 1: if using VUE, your vue version and vue-template-compiler packages in package.json should match version numbers exactly.
// Note 2: if using VUE, import like this:

// REMOVING VUE AS QUIZ IS NO LONGER WANTED
// var Vue = require('vue');
// window.Vue = Vue;
//
// import Quiz from './vue/quiz.vue';
// Vue.component('quiz', Quiz);

// var VuePaginate = require('vue-paginate');
// https://github.com/TahaSh/vue-paginate
// Vue.use(VuePaginate);

$(document).ready(() => {
  /* eslint-disable no-new */
	// if ($('#app').length) {
	// 	new Vue({
	// 		el: '#app',
	// 		components: {
  //       // Quiz
	// 		}
	// 	});
	// }

	// Init foundation on doc ready
	$(document).foundation();

  // Object Fit Polyfill
  // https://github.com/bfred-it/object-fit-images
	objectFitImages();

	// Initialise imported js (this would be js required for every page, globally, i.e. headers
	// Object.init();
	desktopNav.init();
	slider.init();
	relatedSlider.init();
	gallery.init();
	relatedItems.init();
	tabGallery.init();
	tabGalleryDesktop.init();
	// JsIas.init();
	EmagineInfiniteScroll.init();

	// Conditionally import and initialise additional chunks
	// See @ https://webpack.js.org/guides/code-splitting/ && https://sebastiandedeyne.com/posts/2018/code-splitting-with-laravel-mix
	// Depending on the page, these additional components may or may not be needed.
});
