const relatedSlider = {
  init() {

    $('.related-images').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: 1,
      autoplaySpeed: 2000,
      arrows: true,
      nextArrow: '<img class="prev-mobile" src="/images/arrow-left-mobile.png">',
      prevArrow: '<img class="next-mobile" src="/images/arrow-right-mobile.png">',
      responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              nextArrow: '<img class="prev" src="/images/arrow-left.svg">',
              prevArrow: '<img class="next" src="/images/arrow-right.svg">',
            }
          },
        ]
    });





    // close init
  }
  // close const
};

export default relatedSlider;
