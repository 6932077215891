const desktopNav = {
  init() {

    $(".has-dropdown").hover(function() {
      $(".dropdown").css("display", "flex")
    });

    $(".has-dropdown").mouseleave(function() {
      $(".dropdown").css("display", "none")
    });

    $(".dropdown").mouseenter(function() {
      $(".dropdown").css("display", "flex")
    });

    $(".dropdown").mouseleave(function() {
      $(".dropdown").css("display", "none")
    });

    // close init
  }
  // close const
};

export default desktopNav;
