// import infiniteScroll from 'infinite-scroll';

const EmagineInfiniteScroll = {
	init () {

		/*
		* Infinite scroll
    * https://infinite-scroll.com/
    * https://infinite-scroll.com/options.html
		*/

    if ( $('.infinite-scroll-container').length ) {
      $('.infinite-scroll-container').infiniteScroll({
        // options
        path: '.infinite-scroll-next',
        append: '.infinite-scroll-item',
        history: false,
        prefill: false, // load pages on init until user can scroll
        loadOnScroll: false,
        scrollThreshold: false,
        button: '.infinite-scroll-button',
        status: '.page-load-status',
        hideNav: '.infinite-scroll-pagination',
      });

      $('.infinite-scroll-container').on( 'append.infiniteScroll', function( event, response, path, items ) {
        console.log( items.length + ' items appended' );
        // Fix Equalizer woes
        $(document).foundation();
        // Foundation.reInit('equalizer');
        Foundation.reInit($('[data-equalizer]'));
      });
    }

    if ( $('.infinite-scroll-container-mobile').length ) {
      $('.infinite-scroll-container-mobile').infiniteScroll({
        // options
        path: '.infinite-scroll-next-mobile',
        append: '.infinite-scroll-item-mobile',
        history: false,
        prefill: false, // load pages on init until user can scroll
        loadOnScroll: false,
        scrollThreshold: false,
        button: '.infinite-scroll-button-mobile',
        status: '.page-load-status',
        hideNav: '.infinite-scroll-pagination-mobile',
      });

      $('.infinite-scroll-container-mobile').on( 'append.infiniteScroll', function( event, response, path, items ) {
        console.log( items.length + ' items appended' );
        // Fix Equalizer woes
        $(document).foundation();
        // Foundation.reInit('equalizer');
        Foundation.reInit($('[data-equalizer]'));
      });

    }

	}
};

export default EmagineInfiniteScroll;
