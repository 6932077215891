const homeSlider = {
  init() {

    $('.images').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false
    });

    // close init
  }
  // close const
};

export default homeSlider;
